<template>
	<b-card no-body :title="title" class="mb-0 h-100">
		<b-card-header class="pb-1">
			<b-card-title class="title-container">
				<div style="display: flex; gap: 10px; align-items: center;">
					{{ title }}
					<slot name="custom-top-left" />
				</div>

				<el-tooltip
					v-show="supportPieDetail"
					effect="dark"
					content="Click bar to display detail information"
					placement="top-start"
				>
					<img
						src="@/assets/images/icons/pie-chart.png"
						height="18px"
						width="18px"
						class="pointer-me"
					/>
				</el-tooltip>
			</b-card-title>

			<div style="display: flex; align-items: center; gap: 10px;">
				<slot name="custom-top-right" />
				<span v-show="lastUpdate != null"
					>Last Update: {{ lastUpdate }}</span
				>
			</div>
		</b-card-header>

		<b-card-body id="root" class="w-100 h-100">
			<simple-loading
				:is-loading="isLoading"
				:is-load-failed="loadFail"
				@reload="onReload"
			>
				<div class="chart-container">
					<slot name="before-chart" />

					<div :class="[customStyle ? null : 'remaining-area']">
						<simple-chart
							:options="options"
							:custom-style="customStyle"
							@click="onClick"
						/>
					</div>

					<slot name="after-chart" />
				</div>
			</simple-loading>
		</b-card-body>
	</b-card>
</template>

<script>
	//
	import { BCard, BCardBody, BCardTitle, BCardHeader } from "bootstrap-vue";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import SimpleChart from "./SimpleChart.vue";
	import { ref, computed, watch } from "@vue/composition-api";

	export default {
		components: {
			BCardHeader,
			BCardTitle,
			BCard,
			BCardBody,
			SimpleLoading,
			SimpleChart,
		},
		props: {
			supportPieDetail: {
				type: Boolean,
			},
			title: {
				type: String,
				required: false,
			},
			lastUpdate: {
				type: String,
				required: false,
			},
			options: {
				type: Object,
				required: true,
			},
			isLoading: {
				type: Boolean,
				required: true,
			},
			loadFail: {
				type: Boolean,
				required: true,
			},
			customStyle: {
				type: String,
				required: false,
			},
		},
		setup(props, ctx) {
			function onReload() {
				ctx.emit("reload");
			}

			function onClick(params) {
				ctx.emit("click", params);
			}

			return {
				onReload,
				onClick,
				// wrapperTopRightSelected
			};
		},
	};
</script>

<style>
	#root {
		height: 100%;
	}
	.chart-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.remaining-area {
		/* flex: 1; */
		height: 100%;
		min-height: 120px;
	}
	.title-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.pointer-me {
		cursor: pointer;
	}
</style>
